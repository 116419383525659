<template>
  <div id="app">
	<div id="loading"><Loading v-if="stateLoading"></Loading></div>
    <transition name="fade" mode="in-out"><router-view v-if="isRouterAlive"/></transition>
	<BackTop></BackTop>
	<Footer v-if="!stateLoading"></Footer>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import Footer from '@/components/Footer.vue'
import BackTop from '@/components/BackTop.vue'
export default {
	name: 'App',
  components: {
	Loading,
	Footer,
	BackTop,
  },
  provide () {
     return{
       reload: this.reload,
	   loading: this.loading,
	   loaded: this.loaded
     }
   },
   data() {
     return {
         isRouterAlive: true,
		 darkTheme: false,
     }
   },
   methods:{
     reload(){
       this.isRouterAlive = false
       this.$nextTick(function(){
         this.isRouterAlive = true
       })
     },
	 loading(){
		document.body.style.overflow='hidden'
		document.getElementById('loading').style.display = 'block'
	 },
	 loaded(){
		document.body.style.overflow=''
		document.getElementById('loading').style.display = 'none'
	 }
   },
   computed: {
	   stateLoading(){
		   return this.$store.getters.getLoading
	   },
   },
}
</script>

<style lang="scss">
	:root{
		--theme-color: black;
		--theme-background-color: rgba(255,255,255,.6);
		--theme-qc-background-color: #d058d0;
		--theme-qc-color: #bf62bf;
	}
	* {
		margin: 0;
		padding: 0;
	}
	.content {
	   height: 100%;
	} 
	.backtop {
	    overflow: scroll;
	    height: 100%;
	} 
body,html{
    // background-image: url("/img/bg_main.jpg");
    background-attachment:fixed;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: url("/img/cursor.ico"),auto;
    font: 14px/1.5 Tahoma,Helvetica,Arial,'宋体',sans-serif;
    font-family: "Source Sans Pro","Hiragino Sans GB","Microsoft Yahei",SimSun,Helvetica,Arial,Sans-serif;
    height: 100%;
    width: 100%;
}
body::-webkit-scrollbar {/*滚动条整体样式*/
    width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    transition: all ease-in-out 1s;
}
body::-webkit-scrollbar-thumb{/*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba( 0, 0, 0, .1);
    background: #64d1e2;
}
body::-webkit-scrollbar-track{/*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba( 0, 0, 0, .1);
    border-radius: 10px;
    background: rgb(224,228 ,230);
}
body::-webkit-scrollbar:hover {/*滚动条整体样式*/
    width: 10px;
    height: 1px;
}
body::before{
	content:'';
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	min-height: 100%;
	background-attachment: fixed;
	background: transparent url(/img/bg_main.webp) center center no-repeat;
	// background:transparent url(https://i.loli.net/2020/10/05/H9zeGsK5D7OSCT6.jpg) center center no-repeat;
	filter:blur(5px) brightness(0.85);
	background-size:cover;
	transform: scale(1);
	z-index: -1;
}
@font-face {
 font-display: swap;
}
.pswp__bg{
	opacity: .9 !important;
	background: #1e1e1e !important;
}

.fade-enter-active,.fade-leave-active{
	transition: opacity .5s ease-in-out, transform .5s ease-in-out;
}
.fade-enter,.fade-leave-to{
	opacity: 0;
	transform: translateZ(-30%);
}
@media only screen and (max-width: 770px) {
  //   body,html{
  //       background-image:url("/img/bg_main_m.jpg");
		// background-image: unset;
  //   }
	body::before{
		background-image:url(/img/bg_main_m.webp);
	}
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

.body-dark-theme::before{
	filter: unset;
	background: none;
	background-color: black;
}
.body-dark-theme #bottom-bar{
	background-color: unset;
}
.darkApp{
	// background-color: #38535f;
}
.darkApp #words{
	color: white;
	text-shadow: 2px 2px 1px #909698;
	background-color: unset;
}
.darkApp #words::-moz-placeholder{
	color: white;
}

#nav {
	z-index: 2;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#canvas_sakura{
	z-index: -1;
}
</style>
