import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/index',
    name: 'Index',
	component: () => import('../views/Index.vue')
  },
  {
    path: '/type',
    name: 'Type',
  	component: () => import('../views/Type.vue')
  },
  {
    path: '/tag',
    name: 'Tag',
  	component: () => import('../views/Tag.vue')
  },
  {
    path: '/diary',
    name: 'Diary',
  	component: () => import('../views/Diary.vue')
  },
  {
    path: '/link',
    name: 'Link',
  	component: () => import('../views/Link.vue')
  },
  {
    path: '/whisper',
    name: 'Whisper',
  	component: () => import('../views/Whisper.vue')
  },
  {
    path: '/image',
    name: 'Images',
  	component: () => import('../views/Image.vue')
  },
  {
    path: '/article/:aid',
    name: 'Article',
  	component: () => import('../views/Article.vue')
  },
  {
    path: '/nav',
    name: 'Guide',
  	component: () => import('../views/Guide.vue')
  },
  {
  	  path: '/manual',
  	  name: 'Manual',
  	  component: () => import('../views/Manual.vue')
  },
  {
	  path: '/package',
	  component: () => import('../views/Package.vue')
  },
  {
  	  path: '/fan',
  	  name: 'Fan',
  	  component: () => import('../views/fan/index.vue')
  },
  {
  	  path: '/me',
  	  name: 'Me',
  	  component: () => import('../views/Me.vue'),
	  beforeEnter: (to,from,next) => {
	  	let user = JSON.parse(localStorage.getItem('user'))
	  	if(user&&user.uid!=null){
	  		next()
	  	}else{
			Vue.prototype.$message({
			    message: '请先登录哦(⊙﹏⊙)',
				type: 'error',
			    center: true
			});
			next("/")
	  	}
	  }
  },
  {
	 path: '/admin',
	 name: 'Admin',
	 component: () => import('../views/admin/Admin.vue'),
	 beforeEnter: (to,from,next) => {
		 let user = JSON.parse(localStorage.getItem('user'))
		 if(user&&user.uid==1){
			 next()
		 }else{
			 next('/')
		 }
	 }
  },
  {
  	 path: '/admin/test',
  	 name: 'adminTest',
  	 component: () => import('../views/admin/Test.vue'),
  	 beforeEnter: (to,from,next) => {
  		 let user = JSON.parse(localStorage.getItem('user'))
  		 if(user&&user.uid==1){
  			 next()
  		 }else{
  			 next('/')
  		 }
  	 }
  },
  {
	 path: '/admin/article',
  	 name: 'adminArticle',
  	  component: () => import('../views/admin/Article.vue'),
	  beforeEnter: (to,from,next) => {
	  		 let user = JSON.parse(localStorage.getItem('user'))
	  		 if(user&&user.uid==1){
	  			 next()
	  		 }else{
	  			 next('/')
	  		 }
	  }
  },
  {
	  path: '/only',
	  name: 'Only',
	  component: () => import('../views/qc/Only.vue')
  },
  {
	  path: '/qc',
	  name: 'qcIndex',
	  component: () => import('../views/qc/Index.vue'),
	  // beforeEnter: (to,from,next) => {
	  // 		 if(localStorage.getItem('qc')!=null){
	  // 			 next()
	  // 		 }else{
	  // 			 next('/only')
	  // 		 }
	  // }
  },
  {
	  path: '*',
	  name: 'error',
	  component: () => import('../views/Error.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
  	return {x: 0, y: 0}
  }
})
export default router
