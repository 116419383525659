import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import ElementUI from 'element-ui'
import moment from 'moment'
import mavonEditor from 'mavon-editor'
import VueCropper from 'vue-cropper'
import hljs from 'highlight.js'
import MetaInfo from 'vue-meta-info'
import preview from 'vue-photo-preview'
import VueLazyload from 'vue-lazyload'

import 'vue-photo-preview/dist/skin.css'
import 'highlight.js/styles/googlecode.css'
import 'mavon-editor/dist/css/index.css'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(VueAxios , axios)
Vue.use(VueCookies)
Vue.use(ElementUI)
Vue.use(mavonEditor)
Vue.use(VueCropper)
Vue.use(MetaInfo)
Vue.use(preview)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
})

Vue.config.productionTip = false
axios.defaults.baseURL = '/api'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.common['accessToken'] = 'FA4C308D5E8F6409E01344ADDAEB4C71'
Vue.prototype.$axios = axios
Vue.prototype.$moment = moment
Vue.directive('highlight',function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block)=>{
    hljs.highlightBlock(block)
  })
})

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
	  document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
