<template>
	<el-backtop :bottom="100">
	    <div
	      style="{
	        height: 100%;
	        width: 100%;
	        background-color: #f2f5f6;
	        text-align: center;
	        line-height: 40px;
	        color: #1989fa;
	      }"
		  title="回到顶部"
	    >
	      <img v-lazy="'/img/up.gif'" alt="up" class="up-img">
	    </div>
	  </el-backtop>
</template>

<script>
	export default {
		name: 'BackTop'
	}
</script>

<style scoped>
.el-backtop{
	background-color: unset !important;
}
.el-backtop div{
	line-height: 60px !important;
	background-color: unset !important;
}
.up-img{
	width:70px;
	height: 70px;
	user-select: none;
	border-radius: 50%;
}
@media screen and (max-width: 770px) {
	.up-img{
		width:60px;
		height: 60px;
	}
	.el-backtop{
		right: 25px !important;
	}
}
</style>
