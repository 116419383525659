<template>
	<footer id="bottom-bar" v-loading='loading'
	    element-loading-text="拼命加载中"
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.8)">
	    <div id="num">
	       <p>文章数：<span id="counts">{{ stat.counts }}</span>浏览量：<span id="views">{{ stat.views }}</span>评论数：<span id="comment">{{ stat.comment }}</span></p>
	    </div>
	    <div class="mct">
	        <div>
	            <a target="_blank" href="http://beian.miit.gov.cn/">
					<p>
						<img src="/img/kasuie/must.webp" width="20px" alt="must">
						备案号：蜀ICP备20009266号
					</p>
				</a>
	            <p>© 2020 - 2021 By KASUIE</p>
	            <p>已萌萌哒运行：<span id="time" v-text="time"></span></p>
	        </div>
	    </div>
	</footer>
</template>

<script>
	export default {
		name: 'Footer',
		data() {
			return {
				stat: {},
				time: '9999天',
				loading: true
			}
		},
		methods: {
			getStat(){
				let that = this
				this.$axios.get('statistics').then(function(results){
					that.stat = results.data
					setInterval(that.setTime, 1000)
					that.loading = false
				},function(err){
					console.log(err)
				})
			},
			secondToDate(second) {
			    if (!second) {
			        return 0;
			    }
			    var time = new Array(0, 0, 0, 0, 0);
			    if (second >= 365 * 24 * 3600) {
			        time[0] = parseInt(second / (365 * 24 * 3600));
			        second %= 365 * 24 * 3600;
			    }
			    if (second >= 24 * 3600) {
			        time[1] = parseInt(second / (24 * 3600));
			        second %= 24 * 3600;
			    }
			    if (second >= 3600) {
			        time[2] = parseInt(second / 3600);
			        second %= 3600;
			    }
			    if (second >= 60) {
			        time[3] = parseInt(second / 60);
			        second %= 60;
			    }
			    if (second > 0) {
			        time[4] = second;
			    }
			    return time;
			},
			setTime() {
			        /*此处为网站的创建时间 2020年11月27日*/
			    let creat = Date.UTC(2020,10,27,0,0,0,0);
			    let create_time = Math.round(creat/ 1000);
			    let timestamp = Math.round((new Date().getTime()+8*60*60*1000) / 1000);
			    let currentTime = this.secondToDate((timestamp - create_time));
			    let currentTimeHtml = currentTime[0] + '年' + currentTime[1] + '天'
			        + currentTime[2] + '时' + currentTime[3] + '分' + currentTime[4]
			        + '秒';
			    this.time = currentTimeHtml;
			},
		},
		created() {
			this.getStat()
		}
	}

</script>

<style scoped>
#bottom-bar{
	padding: 1em 0;
	background-color: rgba(255,255,255,.2);
	font-style: italic;
	font-weight: bold;
	margin-top: 5em;
}
#num{
	color: #c3c3c3;
	text-shadow: 1px 1px #0e0707;
}
#num p span{
	padding-right: .5em;
	margin-right: .5em;
	border-right: .2em solid white;
	z-index: 1;
}
.mct{
	margin: 1em 2em 0em;
}
.mct div{
	width: 100%;
	text-align: center;
	color: #c3c3c3;
	text-shadow: 1px 1px #0e0707
}
.mct div a{
	display:inline-block;
	text-decoration:none;
	margin: 0 0 1em
}
.mct div a img{
	vertical-align: middle;
}
.mct div p{
	color: #c3c3c3;
	text-shadow: 1px 1px #0e0707;
}
.mct div p:nth-last-child(1){
	margin-top: .5em
}
.mct div a p{
	margin-top: 0 !important;
}
.darkApp #num,.darkApp .mct div p{
	color: white;
}
</style>
