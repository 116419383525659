import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  		Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',		
  		login: localStorage.getItem('login') ? localStorage.getItem('login') : '',
  		user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {
  			uid: '',
  			username: '',
  			avatar: '',
  			level: '',
  			email: '',
  			createTime: '',
			gender: '',
			birthday: '',
  		},
		loading: true
    },
    mutations: {
  		setLogin(state, user){
  			state.Authorization = user.token
  			state.user.uid = user.uid
  			state.user.username = user.username
  			state.user.level = user.level
  			state.user.avatar = (user.avatar==null?'/img/kasuie/avatar_default.png':user.avatar)
  			state.login = user.login
			state.user.gender = user.gender
			state.user.email = user.email
  			state.user.birthday = user.birthday
  			state.user.createTime = user.createTime
  			localStorage.setItem('Authorization', state.Authorization )
  			localStorage.setItem('login', state.login )
  			localStorage.setItem('user', JSON.stringify(state.user))
  		},
  		logout(){
  			localStorage.clear()
  		},
		updateAvatar(state, avatar){
			state.user.avatar = avatar
			localStorage.setItem('user', JSON.stringify(state.user))
		},
		showLoading(state){
			state.loading = false
		},
		loading(state){
			state.loading = true
		}
    },
    actions: {
    },
    getters: {
    	getUser(state){
    		return state.user
    	},
		getLoading(state){
			return state.loading
		}
    },
    modules: {
    }
})
